import React, { Component, createContext } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { TabKey } from 'types/tab';
import { IOffer, IOrder, OrderState, IOfferWithOrder } from 'types/order';
import { Printer } from 'services/printService';
import { LocalStorageKey, setItem } from 'services/localStorageService';
import { listOrders_orders_edges_node } from './types/graphql/listOrders';
import { isLate, isUrgent, tabStates } from './services/orderService';

const Context = createContext<IState>({
    cancellableUpdatingOrderIds: [] as string[],
    updatingOrderIds: [] as string[],
    onCancellableUpdateStart: (_: string) => { },
    onCancellableUpdateEnd: ({orderId, cancelEvent}: {orderId: string, cancelEvent: boolean}) => { },
    onUpdateStart: (_: string) => { },
    onUpdateEnd: (_: string) => { },
    searchValue: '',
    onSearch: (_: string) => { },
    searchDisplay: false,
    offer: undefined,
    offers: undefined,
    setOffers: (_: IOffer) => { },
    offerDate: undefined,
    setOfferDate: (offerWithOrder: IOfferWithOrder | undefined) => { },
    printer: {
        isModalOpen: false,
        setModalOpen: Function,
        selected: undefined,
        setPrinter: (_: Printer) => { },
    },
    home: {
        currentTab: TabKey.PREPARE,
        setCurrentTab: (_: TabKey) => { },
    },

    orders: {
        [TabKey.PREPARE]: [],
        [TabKey.DISTRIBUTE]: [],
        [TabKey.DISTRIBUTED]: [],
        [TabKey.CANCELLED]: []
    },
    setOrders: (_: TabKey, orders: listOrders_orders_edges_node[]) => { },
    setOrder: () => {},
    refetchOrders: undefined,
    setRefetchOrders: () => {},
    fetchingNextOrders: false,
    setFetchingNextOrders: () => {},
    shouldFetchNewOfferOrders: (shouldRefetch: boolean) => {},
    fetchNewOfferOrders: false,
    counters: {
        [TabKey.PREPARE]: 0,
        [TabKey.DISTRIBUTE]: 0,
        [TabKey.DISTRIBUTED]: 0,
        [TabKey.CANCELLED]: 0
    },
    setCounters: () => {},
    setCountersAtTab: () => {},
    isOrdersLoading : false,
    setIsOrdersLoading: (_: boolean) => { },
    cleanOrdersAndCounters: () => {}
});

type IPrinter = {
    isModalOpen: boolean;
    setModalOpen: Function;
    selected?: Printer;
    setPrinter: Function;
};

type IHome = {
    currentTab: TabKey;
    setCurrentTab: (_: TabKey) => void;
};

type IState = {
    cancellableUpdatingOrderIds: string[];
    updatingOrderIds: string[];
    onCancellableUpdateStart: Function;
    onUpdateStart: Function;
    onCancellableUpdateEnd: Function;
    onUpdateEnd: Function;
    searchValue: string;
    onSearch: Function;
    searchDisplay: boolean;
    offer: undefined | IOffer;
    offers: undefined | IOffer[];
    setOffers: Function;
    offerDate: undefined | IOfferWithOrder;
    setOfferDate: (offer: IOfferWithOrder | undefined) => void;
    printer: IPrinter;
    home: IHome;

    orders: { [key in TabKey]: listOrders_orders_edges_node[] };
    setOrders: Function;
    setOrder: Function;
    refetchOrders: undefined | Function;
    setRefetchOrders: Function;
    fetchingNextOrders: boolean;
    setFetchingNextOrders: Function;
    shouldFetchNewOfferOrders: Function,
    fetchNewOfferOrders: boolean,
    counters: { [key in TabKey]: number };
    setCounters: Function;
    setCountersAtTab: Function;
    isOrdersLoading : boolean;
    setIsOrdersLoading: Function;
    cleanOrdersAndCounters: Function;
};

class AppContext extends Component<any, IState> {
    onCancellableUpdateStart = (orderId: string) => {
        this.setState((state) => {
            return {
                cancellableUpdatingOrderIds: [...state.cancellableUpdatingOrderIds, orderId],
            };
        });
    };
    onCancellableUpdateEnd = ({ orderId, cancelEvent = false }: { orderId: string; cancelEvent: boolean }) => {
      const currentOrders: IOrder[] = cloneDeep(this.state.orders[this.state.home.currentTab]) as IOrder[];

        const isRevertingStatusFromPrepareTab = cancelEvent && this.state.home.currentTab === TabKey.PREPARE;
        let newTab;

        if (!cancelEvent || isRevertingStatusFromPrepareTab) {
            const index = currentOrders.findIndex((eachOrder) => eachOrder.id === orderId);
            const order = currentOrders[index];
            currentOrders.splice(index, 1);

            if (isRevertingStatusFromPrepareTab) {
                newTab = Object.entries(tabStates).find(([, value]: [any, OrderState[]]) => value.includes(OrderState.READY));
                order.state = OrderState.READY;
                order.isLate = isLate(order);
                order.isUrgent = isUrgent(order);

                if (newTab && newTab[0]) {
                    newTab = newTab[0];
                    this.state.orders[newTab].push(order);
                }
            }
        }

        // @ts-ignore
        this.setState((state) => {

            return {
                cancellableUpdatingOrderIds: state.cancellableUpdatingOrderIds.filter((id) => id !== orderId),
                isOrdersLoading: false,
                ...(!cancelEvent
                    ? {
                          orders: {
                              ...state.orders,
                              [this.state.home.currentTab]: currentOrders.sort((order1: IOrder, order2: IOrder) => {
                                  const order1Time = order1.withdrawRange[0].getTime();
                                  const order2Time = order2.withdrawRange[0].getTime();
                                  return order1Time > order2Time ? 1 : -1;
                              }),
                          },
                      }
                    : {
                        ...(isRevertingStatusFromPrepareTab && newTab) && {
                            orders: {
                                ...state.orders,
                                [newTab]: this.state.orders[newTab].sort((order1: IOrder, order2: IOrder) => {
                                    const order1Time = order1.withdrawRange[0].getTime();
                                    const order2Time = order2.withdrawRange[0].getTime();
                                    return order1Time > order2Time ? 1 : -1;
                                }),
                                [this.state.home.currentTab]: currentOrders.sort((order1: IOrder, order2: IOrder) => {
                                    const order1Time = order1.withdrawRange[0].getTime();
                                    const order2Time = order2.withdrawRange[0].getTime();
                                    return order1Time > order2Time ? 1 : -1 as number;
                                })
                            },
                            counters: {
                                ...state.counters,
                                [newTab]: state.counters[newTab] + 1,
                            },
                            },
                        ...(!isRevertingStatusFromPrepareTab) && {
                            counters: {
                                ...state.counters,
                                [this.state.home.currentTab]: state.counters[this.state.home.currentTab] + 1,
                            },
                        }
                        }),
            };
        });
    };

    onUpdateStart = (orderId: string) => {
        this.setState((state) => {
            return {
                updatingOrderIds: [...state.updatingOrderIds, orderId],
            };
        });
    };

    onUpdateEnd = (orderId: string) => {
        this.setState((state) => {
            return {
                updatingOrderIds: state.updatingOrderIds.filter((id) => id !== orderId),
            };
        });
    };

    onSearch = (searchValue: string = '') => {
        this.setState({ searchValue });
    };
    setOffers = (offers: IOffer[]) => {
        if(offers === null ){
            setItem(LocalStorageKey.OFFERS_IDS, []);
            setItem(LocalStorageKey.OFFERS_TEMPLATES_IDS, []);
            return this.setState({ offers: [] });
        }
        const offerIds = new Set(offers.map(({ id }) => id));
        const offersTemplatesIfs = offers.reduce((offersTemplatesIds, offer) => {
            return offersTemplatesIds.add(offer.offerTemplateId)
        }, new Set());
        setItem(LocalStorageKey.OFFERS_IDS, offerIds);
        setItem(LocalStorageKey.OFFERS_TEMPLATES_IDS, offersTemplatesIfs);
        return this.setState({ offers });
    };
    setOfferDate = (offerDate: IOfferWithOrder | undefined) => {
        setItem(LocalStorageKey.OFFERS_IDS, offerDate ?  offerDate : []);
        this.setState({ offerDate });
    };

    setOpenPrinterModal = (isModalOpen: boolean) => {
        this.setState((state) => ({
            printer: {
                ...state.printer,
                isModalOpen,
            },
        }));
    };

    setPrinter = (printer: Printer) => {
        this.setState((state) => ({
            printer: {
                ...state.printer,
                selected: printer,
            },
        }));
    };

    setHomeCurrentTab = (currentTab: TabKey) => {
        this.setState((state) => ({
            home: {
                ...state.home,
                currentTab,
            },
        }));
    };

    setOrders = (tab: TabKey, orders: IOrder[]) => {
        this.setState((state) => ({
            orders: {
                ...state.orders,
                [tab]: orders.sort((order1: IOrder, order2: IOrder) => {
                    const order1Time = order1.withdrawRange[0].getTime();
                    const order2Time = order2.withdrawRange[0].getTime();
                    return order1Time > order2Time ? 1 : -1;
                }),
            },
            isOrdersLoading: false,
        }));
    };

    setOrder = (tab, id, orderDetails) => {
        const newOrders = [...this.state.orders[tab]];
        const order = newOrders.find((order) => order.id === id);
        Object.assign(order, orderDetails);

        this.setState((state) => ({
            orders: {
                ...state.orders,
                [tab]: newOrders,
            },
        }));
    };

    setRefetchOrders = (refetch) => {
        if (!this.state.refetchOrders) {
            this.setState(() => ({
                refetchOrders: refetch,
            }));
        }
    };

    setCounters = (counters: { [key in TabKey]: number }) => {
        this.setState((state) => ({
            counters,
        }));
    };

    setCountersAtTab = (tab, counter: number) => {
        this.setState((state) => ({
            counters: {
                ...state.counters,
                [tab]: counter,
            },
        }));
    };

    shouldFetchNewOfferOrders = (shouldRefetch: boolean) => {
        this.setState((state) => ({
            fetchNewOfferOrders: shouldRefetch
        }));
    };

    setFetchingNextOrders = (val) => {
        this.setState((state) => ({
            fetchingNextOrders: val,
        }));
    };

    setIsOrdersLoading = (isOrdersLoading: boolean) => {
        this.setState((state) => ({
            isOrdersLoading,
        }));
    };

    cleanOrdersAndCounters = () => {
        this.setState(() => ({
            counters: {
                [TabKey.PREPARE]: 0,
                [TabKey.DISTRIBUTE]: 0,
                [TabKey.DISTRIBUTED]: 0,
                [TabKey.CANCELLED]: 0
            },
            orders: {
                [TabKey.PREPARE]: [],
                [TabKey.DISTRIBUTE]: [],
                [TabKey.DISTRIBUTED]: [],
                [TabKey.CANCELLED]: []
            },
        }));
    }

    state: IState = {
        cancellableUpdatingOrderIds: [],
        updatingOrderIds: [],
        onCancellableUpdateStart: this.onCancellableUpdateStart,
        onCancellableUpdateEnd: this.onCancellableUpdateEnd,
        onUpdateStart: this.onUpdateStart,
        onUpdateEnd: this.onUpdateEnd,
        searchValue: '',
        searchDisplay: false,
        offer: undefined,
        onSearch: this.onSearch,
        offers: undefined,
        setOffers: this.setOffers,
        offerDate: undefined,
        setOfferDate: this.setOfferDate,
        printer: {
            isModalOpen: false,
            setModalOpen: this.setOpenPrinterModal,
            selected: undefined,
            setPrinter: this.setPrinter,
        },
        home: {
            currentTab: TabKey.PREPARE,
            setCurrentTab: this.setHomeCurrentTab,
        },

        orders: {
            [TabKey.PREPARE]: [],
            [TabKey.DISTRIBUTE]: [],
            [TabKey.DISTRIBUTED]: [],
            [TabKey.CANCELLED]: [],
        },
        setOrders: this.setOrders,
        setOrder: this.setOrder,
        refetchOrders: undefined,
        setRefetchOrders: this.setRefetchOrders,
        counters: {
            [TabKey.PREPARE]: 0,
            [TabKey.DISTRIBUTE]: 0,
            [TabKey.DISTRIBUTED]: 0,
            [TabKey.CANCELLED]: 0,
        },
        setCountersAtTab: this.setCountersAtTab,
        setCounters: this.setCounters,
        fetchingNextOrders: false,
        setFetchingNextOrders: this.setFetchingNextOrders,
        shouldFetchNewOfferOrders: this.shouldFetchNewOfferOrders,
        fetchNewOfferOrders: false,
        isOrdersLoading: false,
        setIsOrdersLoading: this.setIsOrdersLoading,
        cleanOrdersAndCounters: this.cleanOrdersAndCounters
    };

    render() {
        const { children } = this.props;
        return (
            <Context.Provider
                value={{
                    ...this.state,
                    searchDisplay: this.state.searchValue.length > 0,
                }}
            >
                {children}
            </Context.Provider>
        );
    }
}

export default {
    Component: AppContext,
    Context,
};
