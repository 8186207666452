import React, { Component } from 'react';
import styled, { withTheme, DefaultTheme } from 'styled-components';
import Select from 'react-select';

import { ReactComponent as DropDownImg } from 'ressources/img/dropdown.svg';
import selectedIcon from 'ressources/img/selected.svg';


const SelectValue = styled.span`
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
    color: ${({ theme }) => theme.palette.text.dark};
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;

const DropdownIndicator = () => {
    return <DropDownImg />;
};

const borderRadius = 14;
const padding = 20;
const boxShadow = '0 2px 2px 0 rgba(0,0,0,0.17)';
const getCustomStyles = (theme: DefaultTheme, style: any, isMulti: boolean) => {
    return {
        control: (provided: any) => ({
            ...provided,
            minHeight: theme.height.select,
            width: style && style.width ? style.width : theme.width.select,
            paddingLeft: padding - 8,
            paddingRight: padding,
            border: 'none',
            borderRadius,
            boxShadow,
            ':hover': {
                borderColor: theme.palette.common.white,
                backgroundColor: theme.palette.common.white,
                borderRadius,
            },
        }),
        valueContainer:  (provided: any) => ({
            ...provided,
            overflowY: 'auto',
            maxHeight: theme.height.select,
            padding: '8px',
            ...((style && style.valueContainer) ?? {})
        }),
        singleValue: (provided: any) => ({
            ...provided,
            fontSize: theme.typography.fontSizeL,
            color: theme.palette.text.dark,
            fontWeight: theme.typography.fontWeightLight,
            fontFamily: theme.typography.fontFamily,
        }),
        multiValue: (provided: any) => ({
            ...provided,
            fontSize: theme.typography.fontSizeL,
            color: theme.palette.text.dark,
            fontWeight: theme.typography.fontWeightLight,
            fontFamily: theme.typography.fontFamily,
            backgroundColor: theme.palette.background.light,
        }),
        clearIndicator: (prevStyle) => ({
            ...prevStyle,
            ':hover': { color: theme.palette.common.red },
            margin: '0 10px 0 0',
        }),
        option: (_: any, state: any) => {
            return {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding,
                borderRadius,
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.fontSizeL,
                color: theme.palette.text.dark,
                fontWeight: theme.typography.fontWeightLight,
                ':after': {
                    content: state.isSelected ? `url(${selectedIcon})` : '""',
                    display: 'block',
                },
                ':hover': { fontWeight: theme.typography.fontWeightRegular, backgroundColor : theme.palette.background.light },
                backgroundColor: false,
            };
        },
        menu: (provided: any) => ({
            ...provided,
            width: style && style.width ? style.width : theme.width.select,
            boxShadow,
            borderRadius,
        }),
        indicatorSeparator: () => ({}),
    };
};

export type OptionType = { value?: string; label?: string; secondaryLabel?: string; count?: number };

interface IProps {
    options: OptionType[];
    valuePrefix?: string;
    selectedOption?: OptionType | OptionType[];
    defaultValue?: OptionType | OptionType[];
    def?: OptionType | OptionType[];
    handleChange: (value: any, action: any) => void;
    theme: DefaultTheme;
    customLabel?: boolean;
    isMulti?: boolean;
    style?: any;
    isSearchable?: boolean;
}

class AppSelect extends Component<IProps> {
    render() {
        const { options, valuePrefix, selectedOption, handleChange, theme, customLabel, style, defaultValue, isMulti = false, isSearchable = true } = this.props;
        const customOptions = options.map((option) => ({
            ...option,
            label: `${option.secondaryLabel} (${option.count})`,
        }));
        return (
            <Select
                defaultValue={defaultValue}
                isSearchable={isSearchable}
                isMulti={isMulti}
                components={{
                    DropdownIndicator,
                    SingleValue: ({ children }: any) => (
                        <>
                            <SelectValue>
                                {customLabel
                                    ? valuePrefix
                                        ? `${valuePrefix} ${children}`
                                        : selectedOption && customLabel
                                        // @ts-ignore
                                        ? `${selectedOption.secondaryLabel} (${selectedOption.count})`
                                        : `${children}`
                                    : valuePrefix
                                    ? `${valuePrefix} ${children}`
                                    : children}
                            </SelectValue>
                        </>
                    ),
                }}
                // @ts-ignore
                styles={getCustomStyles(theme, style, isMulti)}
                menuPlacement="top"
                value={selectedOption}
                onChange={handleChange}
                options={customLabel ? customOptions : options}
            />
        );
    }
}

export default withTheme(AppSelect);
