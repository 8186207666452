import { IOrder, IOffer } from 'types/order';
import { OfferTemplateWithdrawalType, WithdrawalTypeChoice } from 'types/graphql/globalTypes';
import { getFormattedOrderId, formatFormulaOrders } from 'services/orderService';
import { getItem, LocalStorageKey } from './localStorageService';
import wrap from 'word-wrap';
import { client } from './apollo/client';
import { loader } from 'graphql.macro';
import isEmpty from 'lodash.isempty';
import { t } from 'i18next';

/**
 * Integer value between 1 and 8
 * @see https://reference.epson-biz.com/modules/ref_epos_sdk_js_en/index.php?content_id=66
 */
enum PrintTextSize {
    DEFAULT = 1,
    TITLE = 2,
    MENU = 2,
}

export interface IPrinter {
    name: string;
    ip: string;
    port: number;
}

const PRINT_ORDER = loader('../queries/printOrder.graphql');
/** Abstract driver for printers. */
export abstract class Printer {
    protected _ipAddress: string;

    /** Should we allow choosing this printer in the selection screen */
    abstract get selectable(): boolean;

    /** Printer name in the selection screen */
    abstract get displayName(): string;

    /** Printer information in the selection screen */
    abstract get info(): IPrinter;

    /** Printer status in the selection screen */
    abstract get status(): string;

    /** Check if the specified printer by ip address is accessible */
    abstract isOnline(): Promise<boolean>;

    constructor(ipAddress: string) {
        this._ipAddress = ipAddress;
    }

    /**
     * Prints a receipt for the provided order.
     * The promise should be try/catched: if we fail to print it _will_ be rejected.
     *
     * @param order Order that needs to be printed.
     */
    abstract print(order: IOrder): Promise<void>;
}

/**
 * Pointer to the epson sdk (it is included in the public folder).
 *
 * @see ePOS_SDK_JavaScript_v2.12.0
 */
declare const epson: any;

enum PRINTER_STATUS {
    CONNECTED = 'Connecté',
    OFFLINE = 'Jamais connecté',
}

enum EPSON_PORT {
    SECURE = 8043,
    UNSECURED = 8008,
}

/** Epson driver implementation. */
export class EpsonPrinter extends Printer {
    // ip, port and lastConnected are loaded from localStorage in the browser.
    protected _port: number;
    protected _lastConnected?: Date;

    // Epson SDK handles that allow sending commandes to the printer.
    //
    // Epson model is to consider devices which expose capabilities.
    // => A printer is a generic device (first handle) that expose a printer service (second handle).
    // => Both will be used for different things.
    protected _device?: any;
    protected _printer?: any;
    protected _footer?: CanvasRenderingContext2D;
    protected _header?: CanvasRenderingContext2D;

    set footer(ctx: CanvasRenderingContext2D) {
        this._footer = ctx;
    }

    set header(ctx: CanvasRenderingContext2D) {
        this._header = ctx;
    }

    /** @inheritdoc */
    get selectable(): boolean {
        return true;
    }

    /** @inheritdoc */
    get displayName(): string {
        return `Epson ${this._ipAddress}:${this._port}`;
    }

    /** @inheritdoc */
    get info(): IPrinter {
        return {
            name: `Epson`,
            ip: this._ipAddress,
            port: this._port,
        };
    }

    /** @inheritdoc */
    get status(): string {
        if (this._device && this._printer && this._device.isConnected) {
            return PRINTER_STATUS.CONNECTED;
        } else if (this._lastConnected) {
            return `Vu pour la dernière fois le ${this._lastConnected}`;
        } else {
            return PRINTER_STATUS.OFFLINE;
        }
    }

    constructor(ipAddress: string, port: number = 8008) {
        const host = ipAddress.match(/([0-9]{0,3}\.[0-9]{0,3}\.[0-9]{0,3}\.[0-9]{0,3}):([0-9]+)/);
        if (host) {
            super(host[1]);
            this._port = parseInt(host[2], 10);
        } else {
            super(ipAddress);
            this._port = port;
        }
    }

    /** @inheritdoc */
    async isOnline(): Promise<boolean> {
        try {
            if (!this._device || !this._printer) {
                await this._connect();
            } else {
                return true;
            }
        } catch (e) {
            return false;
        }
        return this.status !== PRINTER_STATUS.OFFLINE;
    }

    /** @inheritdoc */
    async print({
        idWithdrawal,
        id,
        orderItems,
        guest: { firstName, lastName },
        created,
        offer,
        withdrawRange,
        tableNumber,
        paymentMethod,
        pickupPoint,
        orderItemsFormula,
        comment,
        withdrawalType,
    }: IOrder): Promise<void> {
        const { name: label } = offer.offerTemplate;
        const offerTemplateWithdrawalType = offer.offerTemplate.withdrawalType;
        if (!this._device || !this._printer) {
            await this._connect();
        }

        const orderId = () => {
            if (offerTemplateWithdrawalType === OfferTemplateWithdrawalType.CONNECTED_LOCKERS) {
                let orderId = getFormattedOrderId(id);
                return 'ID: ' + orderId;
            } else {
                return null;
            }
        };

        const formattedFormulaItems = formatFormulaOrders(orderItemsFormula || []);

        const onSite = paymentMethod === 'ON_SITE' ? 'PAIEMENT SUR PLACE' : null;
        let storedNumberOfTickets = Number(getItem(LocalStorageKey.TICKETS_NUMBER));
        if (storedNumberOfTickets === 0) storedNumberOfTickets = 1;

        for (var i = 0; i < storedNumberOfTickets; i++) {
            this._printer
                .addTextAlign(this._printer.ALIGN_CENTER)
                .addImage(this._header, 0, 0, 320, 104)
                .addText('\n\n')
                .addTextSize(PrintTextSize.TITLE, PrintTextSize.TITLE)
                .addText(
                    [
                        offerTemplateWithdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE
                            ? `N° de Table: ${tableNumber}\n`
                            : null,
                        `COMMANDE #${idWithdrawal}`,
                        orderId(),
                        onSite,
                        `${firstName} ${lastName}`,
                    ]
                        .filter((item) => item !== null)
                        .join('\n')
                );

            if ((pickupPoint && pickupPoint.name) || withdrawalType || comment) {
                this._printer
                  .addTextSize(PrintTextSize.DEFAULT, PrintTextSize.DEFAULT)
                  .addText('\n————————————————————————————————');
            }

            this._printer
                .addText(pickupPoint && pickupPoint.name ? '\n\n' : '')
                .addTextSize(PrintTextSize.DEFAULT, PrintTextSize.DEFAULT)
                .addText(pickupPoint && pickupPoint.name ? 'Point de retrait:\n' : '')
                .addTextSize(PrintTextSize.DEFAULT, PrintTextSize.TITLE)
                .addText(pickupPoint && pickupPoint.name ? `${pickupPoint.name}\n` : '')
                .addTextFont(this._printer.FONT_A)
                .addTextSize(PrintTextSize.TITLE, PrintTextSize.TITLE)
                .addText(
                    isEmpty(withdrawalType)
                        ? ''
                        : `\n\n ${
                        (withdrawalType === WithdrawalTypeChoice.ON_SITE
                                  ? t('page:home.orderCard.eatIn')
                                  : t('page:home.orderCard.takeAway')).toUpperCase()
                          }\n`
                )
               .addTextFont(this._printer.FONT_B)
               .addTextSize(PrintTextSize.TITLE, PrintTextSize.TITLE)
               .addText(isEmpty(comment) ? '' : `\n\nCommentaire: ${comment}`)
               .addTextFont(this._printer.FONT_A)
               .addTextSize(PrintTextSize.DEFAULT, PrintTextSize.DEFAULT)
               .addText('\n\n————————————————————————————————\n\n')
               .addTextSize(PrintTextSize.MENU, PrintTextSize.MENU)
               .addTextFont(this._printer.FONT_C)
               .addTextStyle(false, false, true, undefined)
               .addTextLineSpace(30)
               .addTextAlign(this._printer.ALIGN_LEFT);

            if (formattedFormulaItems && formattedFormulaItems.length > 0) {
                formattedFormulaItems.forEach((eachFormulaParent) => {
                    const formulaWrapLabel: string = wrap(eachFormulaParent.labelWhenAdded, {
                        width: 28,
                        newline: '\n',
                        trim: true,
                    });
                    const formulaLabel: string = formulaWrapLabel.split('\n')[0].trim();

                    this._printer
                        .addTextSize(PrintTextSize.MENU, PrintTextSize.MENU)
                        .addText(`${eachFormulaParent.quantity} x ${formulaLabel}`)
                        .addText('\n');

                    if (eachFormulaParent.containerLabelWhenAdded) {
                        let containerWrapLabel: string = wrap(eachFormulaParent.containerLabelWhenAdded, {
                            width: 20,
                            newline: '\n',
                            trim: true,
                        });
                        const containerLabel: string = containerWrapLabel.split('\n')[0].trim();

                        this._printer.addText(`+ Consigne: ${containerLabel}`).addText('\n');
                    }

                    this._printer
                        .addTextSize(PrintTextSize.DEFAULT, PrintTextSize.MENU)
                        .addTextFont(this._printer.FONT_A)
                        .addTextLineSpace(30)
                        .addText('\n');

                    // prints the sub-products of each formula
                    eachFormulaParent.subItems.forEach((eachSubproduct) => {
                        const subproductWrapLabel: string = wrap(eachSubproduct.labelWhenAdded, {
                            width: 28,
                            newline: '\n',
                            trim: true,
                        });
                        const subproductLabel: string = subproductWrapLabel.split('\n')[0].trim();

                        this._printer.addText(`  - ${eachSubproduct.quantity} x ${subproductLabel}`).addText('\n');
                    });
                });
                this._printer
                    .addTextSize(PrintTextSize.MENU, PrintTextSize.MENU)
                    .addTextFont(this._printer.FONT_C)
                    .addTextLineSpace(30);
            }

            this._printer
                .addText(
                    [
                        // @ts-ignore
                        ...orderItems.map((oi) => {
                            let wrapLabel: string = wrap(oi.offerItem!.inheritedLabel, {
                                width: 28,
                                newline: '\n',
                                trim: true,
                            });
                            const aLabel: string = wrapLabel.split('\n')[0].trim();
                            const baking = oi.chosenBaking ? t(`schema:article.baking.${oi.chosenBaking}`) : '';
                            const item = `${oi.quantity} x ${aLabel} - ${baking}`;
                            if (oi.containerLabelWhenAdded) {
                                let c_wrapLabel: string = wrap(oi.containerLabelWhenAdded, {
                                    width: 20,
                                    newline: '\n',
                                    trim: true,
                                });
                                const c_aLabel: string = c_wrapLabel.split('\n')[0].trim();
                                const container = `+ Consigne: ${c_aLabel}`;
                                return `${item}\n${container}`;
                            }
                            return item;
                        }),
                    ].join('\n')
                )
                // we need to have this extra '\n' otherwise the next text won't be centered
                .addText('\n')
                .addTextLineSpace(30)
                .addTextAlign(this._printer.ALIGN_CENTER)
                .addTextSize(PrintTextSize.DEFAULT, PrintTextSize.DEFAULT)
                .addTextFont(this._printer.FONT_A)
                .addText('\n————————————————————————————————\n\n')
                .addText(label)
                .addText('\n')
                .addText(
                    [
                        `Commande: ${new Date(created).toLocaleDateString('fr-FR', {
                            month: '2-digit',
                            year: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                        })}`,
                        offerTemplateWithdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE ||
                        offerTemplateWithdrawalType === OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT
                            ? null
                            : `Retrait: ${withdrawRange[0].toLocaleDateString('fr-FR', {
                                  month: '2-digit',
                                  year: '2-digit',
                                  day: '2-digit',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  second: '2-digit',
                              })}`,
                        ``,
                    ].join('\n')
                )
                .addText('\n')
                .addText(
                    [
                        'Consommation le lendemain ou dans la limite de',
                        'la DLC indiquée si possibilité de stocker entre',
                        '0 et 3°C, ou dans l’heure suivant l’achat',
                        'en l’absence de moyen de conservation.',
                    ]
                        .filter((item) => item !== null)
                        .join('\n')
                )
                .addImage(this._footer, 0, 0, 682, 166)
                .addText('\n\n\n')
                .addCut(this._printer.CUT_FEED);
        }

        return new Promise((resolve, reject) => {
            this._printer.onreceive = function (res: any) {
                resolve();
            };

            this._printer.onerror = function (err: any) {
                reject();
            };

            this._printer.send();
        });
    }

    protected async _connect(): Promise<void> {
        // Clean up previous connections.
        await this._disconnect();

        this._device = new epson.ePOSDevice();

        // Connect to generic device.
        await new Promise((resolve, reject) => {
            this._device.connect(
                this._ipAddress,
                this._port,
                (res: string) => {
                    if (res === 'OK' || res === 'SSL_CONNECT_OK') resolve(res);
                    else reject(res);
                },
                { eposprint: true }
            );
        });

        // Create a printer device from the connection.
        this._printer = await new Promise((resolve, reject) => {
            const deviceId = 'local_printer';
            const options = { crypto: false, buffer: false, secure: this._port === EPSON_PORT.SECURE };

            this._device.createDevice(
                deviceId,
                this._device.DEVICE_TYPE_PRINTER,
                options,
                (deviceObj: any, errorCode: any) => {
                    if (deviceObj) resolve(deviceObj);
                    else reject(errorCode);
                }
            );
        });
    }

    protected async _disconnect(): Promise<void> {
        // Discards the Printer object
        if (this._printer) {
            await new Promise<void>((resolve, reject) => {
                this._device.deleteDevice(this._printer, (errorCode: any) => resolve());
            });
            this._printer = null;
        }

        // Terminates connection with device
        if (this._device) {
            this._device.disconnect();
            this._device = null;
        }
    }
}

/**
 * Manages printer detection and choosing.
 */
class PrintService {
    protected _selected?: Printer;

    get printer(): Printer | undefined {
        return this._selected;
    }

    set printer(v: Printer | undefined) {
        this._selected = v;
    }

    async print(order: IOrder): Promise<any> {
        // if(order.offer.offerTemplate.pos && order.offer.offerTemplate.pos.posHub && order.offer.offerTemplate.pos.posHub.enabled) {
        //     return client.mutate({
        //         mutation: PRINT_ORDER,
        //         variables: {
        //             idOrder: order.id,
        //         }
        //     })
        // }
        if (this._selected) {
            return await this._selected.print(order);
        } else {
            // sandwich error + reject promise
        }
    }
}

export default new PrintService();
